<template>
    <v-container fluid>
        <v-row>
            <v-container fluid>
                <v-card>
                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-col cols="12" class="pa-2">
                                <v-row class="grey lighten-2 ma-2">
                                    <v-col class="red--text" cols="12"> *El archivo debe ser llamado datos.xlsx </v-col>
                                    <v-col cols="12" sm="8">
                                        <v-file-input label="Seleccionar Archivos" hide-details outlined dense v-model="file"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-btn class="mx-1" :loading="loadingFile" small color="green" dark @click="uploadFile">
                                            CARGAR Y ACTUALIZAR DATOS
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-btn
                                    class="mx-1"
                                    href="https://tienda.zongshenperu.com/wp-content/uploads/2022/02/CATALOGO_UBIGEO.xlsx"
                                    dark
                                    color="blue"
                                    small
                                    download
                                >
                                    DESCARGAR UBIGEOS
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <h5>
                                    <v-btn class="mx-1" small color="orange" dark @click="descargarArchivo"> DESCARGAR DATOS ACTUALES </v-btn>
                                </h5>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-container>
        </v-row>
        <v-row>
            <v-col cols="12"
                ><v-card>
                    <v-card-title dark>
                        <strong>locales</strong>
                        <v-spacer />
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line hide-details />
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            item-key="id_repuestos"
                            flat
                            :headers="headers"
                            :items="lista"
                            :search="search"
                            :loading="loading"
                            :sort-by="['id_repuestos']"
                            :sort-desc="[true]"
                            loading-text="...Cargando"
                        >
                            <template v-slot:[`item.fechaCreacion`]="{ item }">
                                {{ item.fechaCreacion | fechaISO }}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon color="green" @click="openForm(4, item)"> mdi-eye </v-icon>
                                <v-icon color="blue" @click="openForm(2, item)"> mdi-pencil </v-icon>
                                <v-icon color="red" @click="openForm(3, item)"> mdi-delete </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card></v-col
            >
        </v-row>
        <v-dialog v-model="openDialog" v-if="openDialog" persistent max-width="360px">
            <v-card>
                <v-card-title class="grey lighten-5">
                    <strong>{{ modalText }} locales</strong>
                </v-card-title>
                <v-container class="pa-3" align="center">
                    <v-row align="center">
                        <v-col cols="12">
                            <v-text-field
                                v-model="datos.descripcion"
                                v-validate="'required'"
                                dense
                                hide-details="auto"
                                outlined
                                label="nombreContacto*"
                                :error-messages="errors.collect('datos.descripcion')"
                                data-vv-name="datos.descripcion"
                                required
                            />
                        </v-col>

                        <v-col cols="12">
                            <small>*Campos Obligatorios</small>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions class="grey lighten-5">
                    <v-spacer />
                    <v-btn color="darken-1" text @click="EMPTY_FORM"> CERRAR </v-btn>
                    <v-btn v-if="step != 4" :loading="loadingBTN" color="primary" @click="fnHandleCrud"> {{ modalText }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Delete :open="openDelete" :loadingBTN="loadingBTN" @handle-delete="fnHandlePut" @closeModal="closeDocumento" />
    </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { exportXLSX } from '@/utils'
import { mixins } from '@/mixins/mixin.js'
import Delete from '@/components/modals/Delete'
import Documento from '@/components/modals/Documento'

const defaultText = 'REGISTRAR'

export default {
    components: {
        Delete,
        Documento
    },
    mixins: [mixins],

    data() {
        return {
            loadingFile: false,
            menu: false,
            fechaFiltro: new Date().toISOString().substr(0, 7),
            openDialog: false,
            openDelete: false,
            openDocumento: false,
            file: null,
            search: '',
            singleSelect: true,
            loadingBTN: false,
            selected: null,
            modalText: defaultText,
            step: 1,
            loading: false,
            headers: [
                { text: 'idTipoLocal', value: 'idTipoLocal' },
                { text: 'idUbigeo', value: 'idUbigeo' },
                { text: 'canal', value: 'canal' },
                { text: 'razonSocial', value: 'razonSocial' },
                { text: 'telefono', value: 'telefono' },
                { text: 'longitud', value: 'longitud' },
                { text: 'latitud', value: 'latitud' },
                { text: 'nombreContacto', value: 'nombreContacto' },
                { text: 'correo', value: 'correo' },
                { text: 'compania', value: 'compania' }
            ]
        }
    },
    watch: {
        step(val) {
            if (val == 1) {
                this.modalText = defaultText
            } else if (val == 2) {
                this.modalText = 'ACTUALIZAR'
            } else if (val == 3) {
                this.modalText = 'ELIMINAR'
            } else {
                this.modalText = 'VISUALIZAR'
            }
        }
    },
    computed: {
        ...mapGetters('locales', ['lista']),
        ...mapState('locales', ['datos']),
        ...mapState('utilities', ['tipoDocumento'])
    },
    methods: {
        ...mapMutations({
            RESET_FORM: 'locales/RESET_FORM',
            UPDATE_DATOS: 'locales/UPDATE_DATOS',
            OPEN_MODAL: 'mainUI/OPEN_MODAL',
            OPEN_BAR: 'mainUI/OPEN_BAR'
        }),
        ...mapActions({
            handleGet: 'locales/obtenerLista',
            handlePost: 'locales/registrar',
            handlePut: 'locales/actualizar'
        }),
        EMPTY_FORM() {
            this.openDialog = false
            this.loading = false
            this.loadingBTN = false
            this.menu = false
            this.selected = null
            this.openDelete = false
            this.openDocumento = false
            this.OPEN_MODAL({ state: false })
            this.step = 1
            this.RESET_FORM()
        },
        openForm(tipo, item) {
            this.step = tipo
            let status = false
            this.selected = item
            if (tipo == 1) {
                this.RESET_FORM()
                status = true
            } else if (tipo == 2) {
                if (!item) {
                    this.OPEN_BAR({ state: true, text: 'No hay selección', color: 'red' })
                } else {
                    status = true
                    this.UPDATE_DATOS(item)
                }
            } else if (tipo == 3) {
                if (!item) {
                    this.OPEN_BAR({ state: true, text: 'No hay selección', color: 'red' })
                } else {
                    this.openDelete = true
                }
            } else if (tipo == 4) {
                if (!item) {
                    this.OPEN_BAR({ state: true, text: 'No hay selección', color: 'red' })
                } else {
                    status = true
                    this.UPDATE_DATOS(item)
                }
            }
            this.openDialog = status
        },
        async fnHandleCrud() {
            this.loadingBTN = true
            this.OPEN_MODAL({ state: true })
            if (this.step == 1) {
                await this.handlePost(this.datos)
            } else {
                const item = this.selected
                await this.handlePut(item)
            }
            await this.fnHandleGet({})
            this.EMPTY_FORM()
        },
        async fnHandlePut() {
            this.loadingBTN = true
            const item = this.selected
            if (this.step == 3) {
                item.activo = 0
            }
            await this.handlePut(item)
            await this.fnHandleGet({})
            this.EMPTY_FORM()
        },
        async fnHandleGet({}) {
            this.loading = true
            await this.handleGet({})
            this.EMPTY_FORM()
        },

        async uploadFile() {
            this.loadingFile = true
            if (this.file && this.file.name =='datos.xlsx') {
                console.log('this.file ', this.file)
                await this.handlePost(this.file)
                await this.fnHandleGet({})
                this.file = null
            } else {
                alert('no ha seleccionado archivo ó no tiene el nombre de datos.xlsx')
            }
            this.loadingFile = false
        },
        closeDocumento(nombreModal) {
            this[nombreModal] = false
        },
        descargarArchivo() {
            this.loading = true
            const headers = [
                'idTipoLocal',
                'idUbigeo',
                'canal',
                'razonSocial',
                'direccion',
                'telefono',
                'longitud',
                'latitud',
                'nombreContacto',
                'correo',
                'compania'
            ]
            const filename = `datos`
            exportXLSX({ headers, campos: headers, arrayData: this.lista }, { filename })
            this.loading = false
        }
    },
    async created() {
        await this.fnHandleGet({})
    }
}
</script>
